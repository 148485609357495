.sidebar {
  height: 80vh;
}

.react-datepicker {
  display: flex !important;
}

/* .content-wrapper { height: 100vh; } */

.content-wrapper {
  height: calc(100vh - calc(3.5rem + 1px));
  overflow-y: auto;
}

.brand-link img { height: 100px !important; }

.hidden { display: none; }

.unread-message-row, .unread-message-row:hover { background-color: rgba(255, 150, 150, 0.3) !important }
